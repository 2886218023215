import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const GET_ACCOUNT_INFO = "getUserAccountInfo";
export const GET_DOY_OFFICES = "getDoyOffices";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_DOY_OFFICES = "setDoyOffices";

const state = {
  user_personal_info: {},
  user_account_info: {},
  doy_offices: []
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  },

  currentDoyOffices(state) {
    return state.doy_offices;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.patch("user", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("financial-info", payload)
        .then(({ data }) => {
          context.commit(SET_ACCOUNT_INFO, payload);

          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_ACCOUNT_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("financial-info", payload)
        .then(({ data }) => {
          context.commit(SET_ACCOUNT_INFO, payload);

          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_DOY_OFFICES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("doys", payload)
        .then(({ data }) => {
          context.commit(SET_DOY_OFFICES, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
  [GET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
  [SET_DOY_OFFICES](state, doy_offices) {
    state.doy_offices = doy_offices;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
