import ApiService from "@/core/services/api.service";

// action types
export const CREATE_PAYMENT_INTENT = "createPaymentIntent";
export const GET_PAYMENT_METHOD = "getPaymentMethod";
export const SET_PAYMENT_METHOD = "setPaymentMethod";
export const CLEAR_PAYMENT_METHOD = "clearPaymentMethod";
export const CREATE_PAYMENT_METHOD = "createPaymentMethod";
export const DELETE_PAYMENT_METHOD = "deletePaymentMethod";


// mutation types
//export const SET_MAP_STYLES = "setMapStyles";

let state = {
  key:
    "pk_test_51IGsv2IrvWhRRB5qXjc6PO2SjDHb3Q7DR8cM8mERgv7T6pspAfrumy7o9qYulQyiHmqQz5b7jvWmyFtM6Xg6TO6t00L6YV228X",
  cardStyle: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  },
  currentPaymentMethod: {}
};

const getters = {
  apiKey(state) {
    return state.key;
  },
  cardStyle(state) {
    return state.cardStyle;
  },
  currentPaymentMethod(state) {
    return state.currentPaymentMethod;
  }
};

const actions = {
  [CREATE_PAYMENT_INTENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("/assignment/checkout", payload)
        .then(response => {
          resolve(response.data.client_secret);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_PAYMENT_METHOD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("/user-payment-method", payload)
        .then(response => {
          context.commit(SET_PAYMENT_METHOD, response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_PAYMENT_METHOD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("/user-payment-method", payload)
        .then(response => {
          context.commit(SET_PAYMENT_METHOD, payload);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [DELETE_PAYMENT_METHOD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete("/user-payment-method", payload)
        .then(response => {
          context.commit(CLEAR_PAYMENT_METHOD);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_PAYMENT_METHOD](state, data) {
    state.currentPaymentMethod = data;
  },
  [CLEAR_PAYMENT_METHOD](state) {
    state.currentPaymentMethod = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
