const ID_TOKEN_KEY = "_data_";

export const getToken = () => {
  const storeData = window.localStorage.getItem(ID_TOKEN_KEY);
  let val = null;

  if (storeData !== null) {
    const data = JSON.parse(storeData);
    const exp = new Date(data.dt);
    val = Date.now() < exp.getTime() ? data.val : null;
  }
  return val;
};

export const saveToken = (token, minutes) => {
  const data = {
    val: token, //Bearer token
    dt: new Date(Date.now() + minutes * 60 * 1000) // Expiration
  };
  window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(data));
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };