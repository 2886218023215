import ApiService from "@/core/services/api.service";

// action types
export const GET_MAP_STYLES = "getMapStyles";

// mutation types
export const SET_MAP_STYLES = "setMapStyles";

let state = {
  vueGoogleMapsOptions: {
    load: {
      key: "AIzaSyDNt2hyuqCFgXdVzciWufhK1IELxxQurUk",
      libraries: "places", // This is required if you use the Autocomplete plugin
      // libraries: 'places,drawing,visualization'
      // v: '3.26',
      region: "GR",
      language: "gr"
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
  },
  styles: []
};

const getters = {
  vueGoogleMapsOptions(state) {
    return state.vueGoogleMapsOptions;
  },

  mapStyles(state) {
    return state.styles;
  }
};

const actions = {
  [GET_MAP_STYLES](context) {
    return new Promise((resolve, reject) => {
      ApiService.xmlGet("static/styles.json")
        .then(data => {
          context.commit(SET_MAP_STYLES, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_MAP_STYLES](state, styles) {
    state.styles = styles;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
