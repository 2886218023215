import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  SET_ACCOUNT_INFO,
  SET_PERSONAL_INFO
} from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const USER = "user";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {

      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          context
            .dispatch(USER)
            .then(({ userData }) => {
              resolve(userData);
            })
            .catch(({ userError }) => {
              reject(userError);
            });
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("logout")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(PURGE_AUTH);
          context.commit(SET_ACCOUNT_INFO, {});
          context.commit(SET_PERSONAL_INFO, {});
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          //context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("user")
        .then(({ data }) => {
          if(!data.photo) {
            let randomInt = Math.floor(Math.random() * 7);
            if(randomInt == 0){
              randomInt = 1;
            }
            data.photo = `media/buildingid-avatars/${randomInt}.png`;
          }

          context.commit(SET_ACCOUNT_INFO, data);
          context.commit(SET_PERSONAL_INFO, data);
          state.user = data;
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken() && state.isAuthenticated) {
        const persInfo = context.getters.currentUserPersonalInfo;
        if (!("fullName" in persInfo) || persInfo.fullName === "") {
          context
            .dispatch(USER)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              context.commit(PURGE_AUTH);
              reject(false);
            });
        } else {
          resolve(true);
        }
      } else {
        context.commit(PURGE_AUTH);
        reject(false);
      }
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, loginData) {
    state.isAuthenticated = true;
    state.errors = {};
    const expires = parseInt(loginData.expires.interval) - 1;
    JwtService.saveToken(loginData.token, expires);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
