import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/cases",
          name: "cases",
          component: () => import("@/view/pages/Cases.vue")
        },
        {
          path: "/cases/checkout",
          name: "checkout",
          component: () => import("@/view/pages/Checkout.vue")
        },
        {
          path: "/purchased-cases",
          name: "purchased-cases",
          component: () => import("@/view/pages/PurchasedCases.vue")
        },
        {
          path: "/education",
          name: "education",
          component: () => import("@/view/pages/Education.vue")
        },
        {
          path: "/invoices",
          name: "invoices",
          component: () => import("@/view/pages/Invoices.vue")
        },
        {
          path: "/contact",
          name: "contact",
          component: () => import("@/view/pages/Contact.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    }
  ]
});
